import { makePersisted } from "@solid-primitives/storage";
import { createStore } from "solid-js/store";

export interface UtmStoreType {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_term?: string;
  utm_created?: string;
}

const storeKeys = [
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_term",
] as const;

export function utmStoreGetAll(): UtmStoreType {
  const [utmStore] = makePersisted(createStore<UtmStoreType>({}), {
    name: "utm",
  });

  const created = parseInt(utmStore.utm_created || "0");
  const createdLegacy = parseInt(window.localStorage.utm_created);

  // If stored campaign is older than 7 days, clear it.
  const sevenDays = 60 * 60 * 24 * 7;
  if (created !== null && created < ((Date.now() / 1000) | 0) - sevenDays) {
    utmStoreClear();
  }

  if (
    createdLegacy !== null &&
    createdLegacy < ((Date.now() / 1000) | 0) - sevenDays
  ) {
    utmStoreClear();
  }

  return {
    utm_campaign: utmStore.utm_campaign || window.localStorage.utm_campaign,
    utm_source: utmStore.utm_source || window.localStorage.utm_source,
    utm_medium: utmStore.utm_medium || window.localStorage.utm_medium,
    utm_content: utmStore.utm_content || window.localStorage.utm_content,
    utm_term: utmStore.utm_term || window.localStorage.utm_term,
  };
}

export function utmStoreGet(key: keyof UtmStoreType): string | undefined {
  const [utmStore] = makePersisted(createStore<UtmStoreType>({}), {
    name: "utm",
  });

  const created = parseInt(utmStore.utm_created || "0");
  const createdLegacy = parseInt(window.localStorage.utm_created);

  // If stored campaign is older than 7 days, clear it.
  const sevenDays = 60 * 60 * 24 * 7;
  if (created !== null && created < ((Date.now() / 1000) | 0) - sevenDays) {
    utmStoreClear();
  }

  if (
    createdLegacy !== null &&
    createdLegacy < ((Date.now() / 1000) | 0) - sevenDays
  ) {
    utmStoreClear();
  }

  return utmStore[key] || window.localStorage[key]?.replace(/"/g, "");
}

export function utmStoreSet(params: URLSearchParams): void {
  if (
    typeof window.Cookiebot !== "undefined" &&
    window.Cookiebot.hasResponse &&
    !window.Cookiebot.consent.statistics
  ) {
    return;
  }

  const [, utmStoreSet] = makePersisted(createStore<UtmStoreType>({}), {
    name: "utm",
  });

  storeKeys.forEach((k) => {
    const v = params.get(k) ? params.get(k) : undefined;

    if (v) {
      utmStoreSet(k, params.get(k)!);
      window.localStorage.setItem(k, params.get(k)!);
    } else {
      utmStoreSet(k, undefined);
      window.localStorage.removeItem(k);
    }
  });

  utmStoreSet("utm_created", String((Date.now() / 1000) | 0));
  window.localStorage.setItem("utm_created", String((Date.now() / 1000) | 0));
}

export function utmStoreClear() {
  const [, utmStoreSet] = makePersisted(createStore<UtmStoreType>({}), {
    name: "utm",
  });

  for (const k of storeKeys) {
    utmStoreSet(k, undefined);
    window.localStorage.removeItem(k);
  }

  utmStoreSet("utm_created", undefined);
  window.localStorage.removeItem("utm_created");
}
